import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../AuthContext';
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const { adminlogin, login, apipath } = useAuth();
  const navigate = useNavigate();

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const response = await fetch(`${apipath}/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      });

      if (response.ok) {
        const data = await response.json();
        const role  = data.user.role;
        if (role === 'admin') {
          adminlogin(data.user.employee_id, data.access, data.refresh, data.user.email, data.user.name, data.user.role);
          navigate('/dashboard');  // Redirect to the dashboard or another protected route
        } else {
          login(data.user.employee_id, data.access, data.refresh, data.user.email, data.user.name, data.user.role);
          navigate('/employee-dashboard');  // Redirect to the dashboard or another protected route
        }
        iziToast.success({
          title: 'Login Successful',
          message: 'You have successfully logged in!',
          position: 'topCenter'
        });
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'Login failed');
      }
    } catch (err) {
      setError('Something went wrong. Please try again.');
    }
  };

  return (
    <div className="login-container">
      <div className="container pt-4 pb-4">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="login-column">
              <div className="login-img">
                <img src="images/logo1.png" width={120} height={162} alt="Logo" />
              </div>
              <form className="form-row mt-2" onSubmit={handleSubmit}>
                <h4> Login</h4>
                <h6>Please fill in your unique  login details below</h6>
                <div className="mb-3">
                  <label>Email Address</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="form-control"
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    maxLength="50"
                    required
                  />
                </div>
                <div className="mb-2">
                  <label>Password</label>
                  <div style={{ position: 'relative' }}>
                    <input
                      type={passwordVisible ? "text" : "password"}
                      id="password"
                      name="password"
                      className="form-control"
                      placeholder="Enter your password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      maxLength="25"
                      minLength="5"
                      required
                    />
                    <span
                      className="eye-btn"
                      onClick={togglePasswordVisibility}
                    >
                      <i className={`bi ${passwordVisible ? "bi-eye-slash" : "bi-eye"}`}></i>
                    </span>
                  </div>
                </div>
                {error && <p className="text-danger">{error}</p>}
                <div className="login-btn pt-4 pb-4">
                  <button type="submit">Sign in</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
