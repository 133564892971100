import React, { useEffect, useState, useRef } from "react";
import Sidebar from "./AdminSidebar";
import $ from "jquery";
import "datatables.net";
import "bootstrap/dist/js/bootstrap.bundle.min";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../Navbar";
import { Modal } from "react-bootstrap";
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';
import { useAuth } from '../../AuthContext';

const EmployeeManagement = () => {
  const dataTableRef = useRef();
  const [employees, setEmployees] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const { apipath } = useAuth();
  const [employeeData, setEmployeeData] = useState({
    employee_id: "",
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: "",
  });

  const token = localStorage.getItem('adminaccessToken');

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page
  const [searchQuery, setSearchQuery] = useState("");

  // Filter employees based on search query
  const filteredEmployees = employees.filter((employee) =>
    employee.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    employee.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
    employee.role.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate total pages based on filtered employees
  const totalPages = Math.ceil(filteredEmployees.length / itemsPerPage);

  // Get current page data
  const currentEmployees = filteredEmployees.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle search query change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to the first page when searching
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await fetch(`${apipath}/getallusers/`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // Check if the response is ok (status 200-299)
        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
        // Parse the response body as JSON
        const data = await response.json();

        if (Array.isArray(data.employees)) {
          setEmployees(data.employees);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, [apipath, token]);

  const handleShowEdit = (employee) => {
    setCurrentEmployee(employee);
    setEmployeeData(employee);
    setShowEdit(true);
  };

  const handleCloseEdit = () => {
    setShowEdit(false);
    setCurrentEmployee(null);
  };

  const handleShowDelete = (employee) => {
    setCurrentEmployee(employee);
    setShowDelete(true);
  };

  const handleCloseDelete = () => {
    setShowDelete(false);
    setCurrentEmployee(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployeeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (employeeData.password !== employeeData.confirmPassword) {
      // alert("Passwords do not match");
      iziToast.warning({
        title: 'Error',
        message: 'Passwords do not match',
        position: 'topCenter',
      });
      return;
    }
    try {
      const response = await fetch(`${apipath}/register/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          employee_id: employeeData.employee_id,
          name: employeeData.name,
          email: employeeData.email,
          password: employeeData.password,
          role: employeeData.role
        })
      });
      if (!response.ok) {
        // Handle any error that occurred
        const errorData = await response.json();
        iziToast.error({
          title: 'Error',
          message: errorData.message || 'Error occurred while adding the employee',
          position: 'topCenter',
        });
        return;
      }
      iziToast.success({
        title: 'Success',
        message: 'Employee added successfully',
        position: 'topCenter',
      });

      window.location.reload();

      // Fetch the updated list of employees after adding a new one
      const updatedEmployeesResponse = await fetch(`${apipath}/getallusers/`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // setEmployees(updatedEmployeesResponse.data.employees);
      const updatedData = await updatedEmployeesResponse.json();
      setEmployees(updatedData.employees);

      // Hide the modal using Bootstrap's native JavaScript API
      const registerModal = new window.bootstrap.Modal(document.getElementById('registerModal'));
      registerModal.hide();

      setEmployeeData({
        employee_id: "",
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        role: ""
      });
    } catch (error) {
      if (error.response && error.response.status === 400) {
        iziToast.error({
          title: 'Error',
          message: error.response.data.message || 'Employee with this ID or email already exists',
          position: 'topCenter',
        });
      } else {
        iziToast.error({
          title: 'Error',
          message: 'An error occurred while adding the employee',
          position: 'topCenter',
        });
      }
      console.error("Error adding employee:", error);

    }
  };

  const handleDelete = async () => {
    if (currentEmployee) {
      try {
        const response = await fetch(`${apipath}/deleteuser/${currentEmployee.employee_id}/`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setEmployees(employees.filter(employee => employee.employee_id !== currentEmployee.employee_id));
        handleCloseDelete();
        if (response.ok) {
          iziToast.success({
            title: 'Success',
            message: 'Employee deleted successfully',
            position: 'topCenter',
          });
        }
      } catch (error) {
        console.error("Error deleting user:", error);
        iziToast.error({
          title: 'Error',
          message: 'Error deleting employee: ' + (error.response?.data?.msg || 'Unknown error'),
          position: 'topCenter',
        });
      }
    }
  };

  // Add a new function to handle the update logic
  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apipath}/update-user/${employeeData.employee_id}/`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          role: employeeData.role,
          employee_id: employeeData.employee_id,
          name: employeeData.name,
          email: employeeData.email,
          password: employeeData.password
        })
      });

      if (!response.ok) {
        // Handle any error that occurred during update
        const errorData = await response.json();
        iziToast.error({
          title: 'Error',
          message: errorData.message || 'Error occurred while updating the employee',
          position: 'topCenter',
        });
        return;
      }

      iziToast.success({
        title: 'Success',
        message: 'Employee updated successfully',
        position: 'topCenter',
      });

      window.location.reload();

      // Fetch the updated list of employees after updating one
      const updatedEmployeesResponse = await fetch(`${apipath}/getallusers/`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const updatedData = await updatedEmployeesResponse.json();
      setEmployees(updatedData.employees);

      // Hide the edit modal
      setShowEdit(false);
      setCurrentEmployee(null);
    } catch (error) {
      iziToast.error({
        title: 'Error',
        message: 'An error occurred while updating the employee',
        position: 'topCenter',
      });
      console.error("Error updating employee:", error);
    }
  };

  useEffect(() => {
    if (employees.length > 0) {
      $(dataTableRef.current).DataTable();
    }
  }, [employees]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      <Sidebar />
      <Navbar />

      <div className="home-main-content">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Employee Management</h1>
          </div>
          <div className="container-fluid">
            <div className="row foot-tablerow">
              <div className="col-lg-12 maintable-column">
                <div className="container-fluid mt-4 overflow-auto">
                  <div className="d-flex justify-content-between mb-3">
                    {/* Search Bar */}
                    <input
                      type="text"
                      className="form-control w-25"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                    <button
                      className="btn btn-dark"
                      data-bs-toggle="modal"
                      data-bs-target="#registerModal"
                    >
                      <i className="fa-solid fa-user-plus"></i> Add Employee
                    </button>
                  </div>
                  <table id="datatable" className="table">
                    <thead>
                      <tr className="tr1">
                        <th>S.No.</th>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Joining Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentEmployees.map((employee, index) => (
                        <tr key={employee.employee_id}>
                          <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                          <td>{employee.employee_id}</td>
                          <td>{employee.name}</td>
                          <td className="ellipsis">
                            <abbr
                              className="text-decoration-none me-4"
                              title={employee.email}
                            >
                              {employee.email}
                            </abbr>
                          </td>
                          <td>{employee.role}</td>
                          <td>{formatDate(employee.date_joined)}</td>
                          <td className="td1">
                            <i
                              className="fa-regular fa-pen-to-square me-1"
                              onClick={() => handleShowEdit(employee)}
                            ></i>
                            <i
                              className="fa-solid fa-trash"
                              onClick={() => handleShowDelete(employee)}
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* Pagination Controls */}
                  <div className="pagination-controls d-flex justify-content-center">
                    <button
                      className="btn btn-light me-2"
                      disabled={currentPage === 1}
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      Previous
                    </button>
                    {Array.from({ length: totalPages }, (_, index) => (
                      <button
                        key={index}
                        className={`btn ${currentPage === index + 1 ? "btn-dark" : "btn-light"
                          } me-1`}
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </button>
                    ))}
                    <button
                      className="btn btn-light"
                      disabled={currentPage === totalPages}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="registerModal"
        tabIndex="-1"
        aria-labelledby="registerModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="login-head">
                <h5>Add Employee</h5>
                <p>Fill in the details to add a new employee</p>
              </div>
              <form className="login-form" onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label>Role</label>
                  <select
                    className="form-control"
                    name="role"
                    value={employeeData.role}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Role</option>
                    <option value="admin">Admin</option>
                    <option value="employee">Employee</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label>Employee ID</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Employee ID"
                    name="employee_id"
                    value={employeeData.employee_id}
                    onChange={handleChange}
                    required
                    onKeyPress={(e) => {
                      if (e.target.value.length >= 8 || e.key === "-" || e.key === "e") {
                        e.preventDefault(); // Block additional input or invalid characters
                      }
                    }}
                    onInput={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = ""; // Reset input if a negative value is entered
                      }
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                    name="name"
                    value={employeeData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email"
                    name="email"
                    value={employeeData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label>Password</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Enter Password"
                    name="password"
                    value={employeeData.password}
                    onChange={handleChange}
                    maxLength="25"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label>Confirm Password</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    value={employeeData.confirmPassword}
                    onChange={handleChange}
                    maxLength="25"
                    required
                  />
                </div>
                <div className="get-otp">
                  <button type="submit">Create Account</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showEdit} onHide={handleCloseEdit} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="login-form" onSubmit={handleUpdateSubmit}>
            <div className="mb-3">
              <label>Employee ID</label>
              <input
                type="text"
                className="form-control"
                value={employeeData.employee_id}
                readOnly
              />
            </div>
            <div className="mb-3">
              <label>Role</label>
              <input
                type="text"
                className="form-control"
                name="role"
                value={employeeData.role}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={employeeData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={employeeData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label>Update Password</label>
              <input
                type="password"
                className="form-control"
                name="password"
                placeholder="Enter only if you want to change the password"
                // value={employeeData.password}
                onChange={handleChange}
              />
            </div>
            <div className="get-otp">
              <button type="submit">Update Employee</button>
            </div>
          </form>
        </Modal.Body>
      </Modal>;
      <Modal show={showDelete} onHide={handleCloseDelete} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-content1">
            <p>Do you want to delete this employee?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary " onClick={handleCloseDelete}>
            Cancel
          </button>
          <button className="btn  btn-danger" onClick={handleDelete}>
            Delete
          </button>

        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EmployeeManagement;








