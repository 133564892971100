import React, { useEffect, useState } from "react";
import EmployeeSidebar from "./EmployeeSidebar";
import Navbar from "../Navbar";
import { useAuth } from '../../AuthContext';
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';


const Documents = () => {
    const [reports, setReports] = useState([]);
    const { apipath } = useAuth();
    const token = localStorage.getItem('accessToken');
    const [openDropdownId, setOpenDropdownId] = useState(null);

    useEffect(() => {
        fetchReports();
    }, [apipath]);

    const fetchReports = () => {
        fetch(`${apipath}/reports/`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                setReports(data);
            })
            .catch(error => {
                console.error("There was an error fetching the reports!", error);
            });
    };

    const handleUpdateStatus = (reportId, newStatus) => {
        fetch(`${apipath}/reports/${reportId}/edit/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ report_status: newStatus }),
        })
            .then(response => {
                if (response.status === 403) {
                    iziToast.error({
                        title: 'Error',
                        message: 'You are not allowed to perform this action',
                        position: 'topCenter',
                    });
                    throw new Error('Unauthorized');
                }
                return response.json();
            })
            .then(() => {
                fetchReports();
            })
            .catch(error => {
                if (error.message !== 'Unauthorized') {
                    console.error("Error updating report status", error);
                }
            });
    };

    // Toggle the dropdown
    const toggleDropdown = (reportId) => {
        setOpenDropdownId(openDropdownId === reportId ? null : reportId);
    };

    // Helper functions to filter reports by their status
    const getOngoingReports = () => reports.filter(report => report.report_status === "ongoing");
    const getUnderVerificationReports = () => reports.filter(report => report.report_status === "under_review");
    const getClosedReports = () => reports.filter(report => report.report_status === "published");

    return (
        <>
            <Navbar />
            <EmployeeSidebar />
            <div className="home-main-content">
                <div className="container pt-4">
                    <div className="row">
                        <div className="col-lg-3">
                            <hr />
                            <div className="nav vertical-tab flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <button className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                    <i className="bi bi-folder2-open"></i> Under Edit Reports
                                </button>
                                {/* <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                    <i className="bi bi-folder2-open"></i> Reports Under Review
                                </button>
                                <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                    <i className="bi bi-folder2-open"></i> Published Reports
                                </button> */}
                            </div>
                            <hr />
                        </div>
                        <div className="col-lg-9">
                            <div className="tab-content" id="v-pills-tabContent">
                                {/* Ongoing Reports */}
                                <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                    <div className="row">
                                    {getOngoingReports.length > -1 ? (
                                        <>
                                        {getOngoingReports().map((report) => (
                                            <div className="col-lg-4" key={report.id}>
                                                <div className="document-box">
                                                    <div className="doc-head">
                                                        <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                        <h5>{report.name}</h5>
                                                        <div className="dropdown">
                                                            <i className="bi bi-three-dots-vertical" onClick={() => toggleDropdown(report.id)}></i>
                                                            {openDropdownId === report.id && (
                                                                <div className="dropdown-menu show" style={{ position: 'absolute', background: 'white', zIndex: 1, right: "10px" }}>
                                                                    <button className="dropdown-item" onClick={() => handleUpdateStatus(report.id, "under_review")}>
                                                                        Move for Review
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="doc-info">
                                                        <img src="images/folder.png" width={95} height={95} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        </>
                                    ) : (
                                        <div className="col-lg-12 text-center pt-4">
                                        <p className="pt-4">No ongoing reports available.</p>
                                        </div>
                                    )}
                                    </div>
                                </div>

                                {/* Reports Under Verification */}
                                <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                    <div className="row">
                                        {getUnderVerificationReports().map((report) => (
                                            <div className="col-lg-4" key={report.id}>
                                                <div className="document-box">
                                                    <div className="doc-head">
                                                        <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                        <h5>{report.name}</h5>
                                                        <div className="dropdown">
                                                            <i className="bi bi-three-dots-vertical" onClick={() => toggleDropdown(report.id)}></i>
                                                            {openDropdownId === report.id && (
                                                                <div className="dropdown-menu show" style={{ position: 'absolute', background: 'white', zIndex: 1, right: "10px"  }}>
                                                                    <button className="dropdown-item" onClick={() => handleUpdateStatus(report.id, "published")}>
                                                                        Publish Report
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="doc-info">
                                                        <img src="images/folder.png" width={95} height={95} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {/* Closed Reports */}
                                <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                    <div className="row">
                                        {getClosedReports().map((report) => (
                                            <div className="col-lg-4" key={report.id}>
                                                <div className="document-box">
                                                    <div className="doc-head">
                                                        <img src="images/doc-img.png" width={32} height={32} alt="" />
                                                        <h5>{report.name}</h5>
                                                        <i className="bi bi-check-circle-fill"></i>
                                                    </div>
                                                    <div className="doc-info">
                                                        <img src="images/folder.png" width={95} height={95} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Documents;
