import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./AdminSidebar";
import Navbar from "../Navbar";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../AuthContext';

const Dashboard = () => {
    const { apipath } = useAuth();
    const [totalEmployees, setTotalEmployees] = useState(0);
    const [totalProjects, setTotalProjects] = useState(0);
    const [totalReports, setTotalReports] = useState(0);
    const [totalReportTemplates, setTotalReportTemplates] = useState(0);
    const [reports, setReports] = useState([]);
    const [reportTemplates, setReportTemplates] = useState([]);
    const navigate = useNavigate();
    const [openDropdownId, setOpenDropdownId] = useState(null);

    const token = localStorage.getItem('adminaccessToken');

    // Fetch total employees
    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const employeeResponse = await fetch(`${apipath}/getallusers/`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (!employeeResponse.ok) {
                    throw new Error(`Error: ${employeeResponse.status} ${employeeResponse.statusText}`);
                }
                const users = await employeeResponse.json();
                setTotalEmployees(users.count);
            } catch (error) {
                console.error("Error fetching employees:", error);
            }
        };
        fetchEmployees();
    }, [apipath, token]);

    // Fetch total projects
    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const projectResponse = await fetch(`${apipath}/projects/`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (!projectResponse.ok) {
                    throw new Error(`Error: ${projectResponse.status} ${projectResponse.statusText}`);
                }
                const projects = await projectResponse.json();
                setTotalProjects(projects.length);
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        };
        fetchProjects();
    }, [apipath, token]);

    // Fetch total reports
    useEffect(() => {
        const fetchReports = async () => {
            try {
                const reportResponse = await fetch(`${apipath}/reports/`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (!reportResponse.ok) {
                    throw new Error(`Error: ${reportResponse.status} ${reportResponse.statusText}`);
                }
                const reports = await reportResponse.json();
                setReports(reports);
                setTotalReports(reports.length);
            } catch (error) {
                console.error("Error fetching reports:", error);
            }
        };
        fetchReports();
    }, [apipath, token]);

    // Fetch report templates dynamically
    useEffect(() => {
        const fetchReportTemplates = async () => {
            try {
                const templateResponse = await fetch(`${apipath}/templates/`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (!templateResponse.ok) {
                    throw new Error(`Error: ${templateResponse.status} ${templateResponse.statusText}`);
                }
                const templates = await templateResponse.json();
                setReportTemplates(templates);
                setTotalReportTemplates(templates.length);
            } catch (error) {
                console.error("Error fetching report templates:", error);
            }
        };
        fetchReportTemplates();
    }, [apipath, token]);

    // Helper functions to filter reports by their status
    const getOngoingReports = () => reports.filter(report => report.report_status === "ongoing");
    const getUnderVerificationReports = () => reports.filter(report => report.report_status === "under_review");
    const getClosedReports = () => reports.filter(report => report.report_status === "published");

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    // Toggle the dropdown
    const toggleDropdown = (reportId) => {
        setOpenDropdownId(openDropdownId === reportId ? null : reportId);
    };

    return (
        <>
            <Navbar />
            <Sidebar />
            <div className="home-main-content">
                <div className="container ">
                    <div className="row">
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="dash-column" onClick={() => navigate("/employees")} style={{ cursor: "pointer" }}>
                                <div>
                                    <h4>{totalEmployees}</h4>
                                    <h6>Total Staff</h6>
                                </div>
                                <div>
                                    <img src="images/staff.png" width={40} height={40} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="dash-column" onClick={() => navigate("/admin-project")} style={{ cursor: "pointer" }}>
                                <div>
                                    <h4>{totalProjects}</h4>
                                    <h6>Total Projects</h6>
                                </div>
                                <div>
                                    <img src="images/project.png" width={40} height={40} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="dash-column" onClick={() => navigate("/admin-reports")} style={{ cursor: "pointer" }}>
                                <div>
                                    <h4>{totalReports}</h4>
                                    <h6>Total Reports</h6>
                                </div>
                                <div>
                                    <img src="images/docs.png" width={40} height={40} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="dash-column">
                                <div>
                                    <h5>Create Project</h5>
                                </div>
                                <div>
                                    <Link to="/admin-project">
                                        <img src="images/add.png" width={52} height={52} alt="" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container pt-2">
                    <div className="row">
                        {/* Ongoing Reports */}
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="kanban-column">
                                <div className="kanban-heading">
                                    <div className="d-flex">
                                        <img className="me-2" src="images/report.png" width={20} height={20} alt="" />
                                        <h6>Under Edit Reports</h6>
                                    </div>
                                    <div>
                                        <h4>{getOngoingReports().length}</h4>
                                    </div>
                                </div>
                                {getOngoingReports().map((report) => (
                                    <div className="kanban-content" key={report.id}>
                                        <div className="report-box">
                                            <div className="d-flex">
                                                <img className="me-2" src="images/ongoing.png" width={20} height={20} alt="" />
                                                <h5>{report.name} <br /> <span>{formatDate(report.created_at)}</span></h5>
                                            </div>
                                            <div className="dropdown">
                                                <i className="bi bi-three-dots-vertical" onClick={() => toggleDropdown(report.id)}></i>
                                                {openDropdownId === report.id && (
                                                    <div className="dropdown-menu show pt-0 pb-0" style={{ position: 'absolute', zIndex: 1, right: "10px", }}>
                                                        <button onClick={() => navigate(`/edit-admin-report/${report.id}`)}>
                                                            View Report
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* Reports Under Verification */}
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="kanban-column">
                                <div className="kanban-heading">
                                    <div className="d-flex">
                                        <img className="me-2" src="images/report.png" width={20} height={20} alt="" />
                                        <h6>Under Review Reports</h6>
                                    </div>
                                    <div>
                                        <h4>{getUnderVerificationReports().length}</h4>
                                    </div>
                                </div>
                                {getUnderVerificationReports().map((report) => (
                                    <div className="kanban-content" key={report.id}>
                                        <div className="report-box">
                                            <div className="d-flex">
                                                <img className="me-2" src="images/pending.png" width={20} height={20} alt="" />
                                                <h5>{report.name} <br /> <span>{formatDate(report.created_at)}</span></h5>
                                            </div>
                                            <div className="dropdown">
                                                <i className="bi bi-three-dots-vertical" onClick={() => toggleDropdown(report.id)}></i>
                                                {openDropdownId === report.id && (
                                                    <div className="dropdown-menu show pt-0 pb-0" style={{ position: 'absolute', zIndex: 1, right: "10px", }}>
                                                        <button onClick={() => navigate(`/edit-admin-report/${report.id}`)}>
                                                            View Report
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* Closed Reports */}
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="kanban-column">
                                <div className="kanban-heading">
                                    <div className="d-flex">
                                        <img className="me-2" src="images/report.png" width={20} height={20} alt="" />
                                        <h6>Published Reports</h6>
                                    </div>
                                    <div>
                                        <h4>{getClosedReports().length}</h4>
                                    </div>
                                </div>
                                {getClosedReports().map((report) => (
                                    <div className="kanban-content" key={report.id}>
                                        <div className="report-box">
                                            <div className="d-flex">
                                                <img className="me-2" src="images/closed.png" width={20} height={20} alt="" />
                                                <h5>{report.name} <br /> <span>{formatDate(report.created_at)}</span></h5>
                                            </div>
                                            <div className="dropdown">
                                                <i className="bi bi-three-dots-vertical" onClick={() => toggleDropdown(report.id)}></i>
                                                {openDropdownId === report.id && (
                                                    <div className="dropdown-menu show pt-0 pb-0" style={{ position: 'absolute', zIndex: 1, right: "10px", }}>
                                                        <button onClick={() => navigate(`/edit-admin-report/${report.id}`)}>
                                                            View Report
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* Dynamic Report Templates */}
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="kanban-column">
                                <div className="kanban-heading">
                                    <div className="d-flex">
                                        <img className="me-2" src="images/report.png" width={20} height={20} alt="" />
                                        <h6>Report Templates</h6>
                                    </div>
                                    <div>
                                        <h4>{totalReportTemplates}</h4>
                                    </div>
                                </div>
                                {reportTemplates.map((template) => (
                                    <div className="kanban-content" key={template.id} onClick={() => navigate("/reporttemplates")} style={{ cursor: "pointer" }}>
                                        <div className="report-box">
                                            <div className="d-flex pt-2 pb-2">
                                                <img className="me-2" src="images/ongoing.png" width={20} height={20} alt="" />
                                                <h5>{template.name}</h5>
                                            </div>
                                            <div className="d-flex pt-2 pb-2">
                                                <h5><span>{formatDate(template.created_at)}</span></h5>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
